import React, { useState } from 'react';
import axios from 'axios';
import { useAuthContext } from '../hooks/useAuthContext';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { dispatch } = useAuthContext();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/auth/signup', {email, password}, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      });

      localStorage.setItem('user', JSON.stringify(response.data)); // Set a flag in local storage
      dispatch ({ type: 'LOGIN', payload: response.data }); // Dispatch the action to the reducer
      setLoading(false);
      ReactGA.event({
        category: 'User',
        action: 'Sign Up',
        label: 'Email'
      });
      navigate('/', {state: { fromSignup: true } });
      
    } catch (e) {
        setLoading(false);
        setError(e.response.data.error);
    }

  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/signup/google`, {
        token: credentialResponse.credential,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      });

      ReactGA.event({
        category: 'User',
        action: 'Sign Up',
        label: 'Google'
      });

      localStorage.setItem('user', JSON.stringify(result.data));
      dispatch({ type: 'LOGIN', payload: result.data });
      navigate('/', { state: { fromSignup: true } });
   

    } catch (error) {
      ReactGA.event({
        category: 'User',
        action: 'Sign Up Failure',
        label: 'Google'
      });
      setError('Failed to login with Google.');
    }
  };

  const handleGoogleFailure = (error) => {
    ReactGA.event({
      category: 'User',
      action: 'Sign Up Failure',
      label: 'Google'
    });
    setError('Google Sign In was unsuccessful. Try again later');
  };

  const handleFacebookResponse = async (response) => {
    if (response.accessToken) {
      try {
        const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/signup/facebook`, {
          accessToken: response.accessToken,
        }, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });

        ReactGA.event({
          category: 'User',
          action: 'Sign Up',
          label: 'Facebook'
        });

        localStorage.setItem('user', JSON.stringify(result.data));
        dispatch({ type: 'LOGIN', payload: result.data });
        navigate('/', { state: { fromSignup: true } });
      } catch (error) {
        ReactGA.event({
          category: 'User',
          action: 'Sign Up Failure',
          label: 'Facebook'
        });

        setError('Failed to login with Facebook.');
      }
    } else {
      setError('Facebook Sign In was unsuccessful. Please try again.');
    }
  };


  return (
    <div className="signup-container">
      <h2>Loo konto</h2>
  
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Parool"
      />
      <button
        disabled={loading || !email || !password}
        onClick={handleSubmit}
      >
        Loo konto
      </button>
      {error && <div className="error">{error}</div>}
  
      <h3>Või kasuta neid teenuseid:</h3>
      
      <div className="social-login">
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={handleGoogleFailure}
          text="Loo konto Google'iga"  
        />
      </div>
      
      <div className="social-login">
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          autoLoad={false}
          fields="email"
          callback={handleFacebookResponse}
          textButton="Sign In with Facebook"
          locale="et_EE"  
        />
      </div>
      
      <div>
        Oled juba kasutaja? <a href="/login">Logi sisse</a>
      </div>
    </div>
  );
}

export default SignUp;
